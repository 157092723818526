/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, illimitado chat',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  graphQL: 'https://api.pp.weezchat.fr/graphql',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  extCode: 'MX',
  key: 1,
  id: 'ck5zhdveftehm0b62gw7xzqu9',
  gtm: 'GTM-T4W6X2V',
  gtmCountry: 'GTM-55NQK56',
  completeProfileInputs: ['email'],
  dvp4m: true,
  gsv: null,

  appLink: null,
  didomi: null,
};
